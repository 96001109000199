
  import { defineComponent, ref, onMounted, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import IREInvestmentFilter from '@/components/dropdown/IREInvestmentFilter.vue';

  export default defineComponent({
    name: 'ire-industrial-streets-listing',
    components: {
      Datatable,
      IREInvestmentFilter,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('ireStreetsListing'), [
          translate('ire'),
        ]);
      });

      await store.dispatch(Actions.GET_IRE_COMMON_STREETS, { type: 5 });
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('idColumn'),
          key: 'id',
          sortable: true,
        },
        {
          name: translate('titleAr'),
          key: 'titleAr',
          sortable: true,
        },
        {
          name: translate('titleEn'),
          key: 'titleEn',
          sortable: true,
        },
        {
          name: translate('areaName'),
          key: 'areaName',
          sortable: true,
        },
        {
          name: translate('sort'),
          key: 'sort',
        },
        {
          name: translate('active'),
          key: 'isActive',
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const toggleStatusIreArea = async (ireArea) => {
        const deletedMessage = ireArea.isActive
          ? 'confirmInActive'
          : 'confirmActive';
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate(deletedMessage),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_IRE_COMMON_STREET, ireArea.id);
          await store.dispatch(Actions.GET_IRE_COMMON_STREETS, { type: 5 });
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            const successMsg = ireArea.isActive
              ? 'SUCCESSFULLY_INACTIVATED'
              : 'SUCCESSFULLY_ACTIVEATED';
            Swal.fire({
              text: translate(successMsg),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
          }
        }
      };

      const editIreArea = (id) => {
        router.push({ name: 'ire-industrial-streets-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_IRE_COMMON_STREET_CURRENT_PAGE,
          val
        );
        await store.dispatch(Actions.GET_IRE_COMMON_STREETS, { type: 5 });
        loading.value = false;
      };
      const onTypeChange = async (values) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_IRE_COMMON_STREET_FILTER,
          values.value
        );
        await store.dispatch(Actions.UPDATE_IRE_COMMON_STREET_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_IRE_COMMON_STREETS, { type: 5 });
        loading.value = false;
      };
      const onReset = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_IRE_COMMON_STREET_FILTER, value);
        await store.dispatch(Actions.GET_IRE_COMMON_STREETS, { type: 5 });
        loading.value = false;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        tableData: computed(() => store.getters.ireCommonStreetsList),
        tableHeader,
        toggleStatusIreArea,
        translate,
        totalItems: computed(() => store.getters.ireCommonStreetsListCount),
        pageChanged,
        goTo,
        editIreArea,
        loading,
        can,
        onTypeChange,
        onReset,
      };
    },
  });
